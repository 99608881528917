.corpo {
  padding-left: 10%;
}

.error {
  color: red;
  font-size: 14px;
}

.error-tp{
  color: red;
  font-size: 14px;
  position: absolute;
  margin-top: 77px;
}

.mt-10{
  margin-top: 10px;
}

.subTitle-perguntas {
  text-align: left;
  font-size: 20px;
}

.subTitle-perguntas-manipula {
  text-align: center;
  font-size: 20px;
}

.subTitle-perguntas-manipula-sb {
  text-align: center;
  font-size: 15px;
}

.sb-manipula {
  width: 50%;
  color: #AFAFAF;
  margin-left: 25%;
  margin-top: 5%;
  margin-bottom: 5%;
}

.p-objetivo {
  color: #4E4E4E;
  font-size: 15px;
  margin-top: 6%;
}

.checkbox-reclamacao {
  margin-right: 10px;
}

.ob {
  background-color: #F2F2F2;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 10px;
  height: 38px;
  margin-top: 4px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.boll {
  border: 1px solid #828282;
  border-radius: 50px;
  padding: 6px;
  margin-top: 3px;
  margin-left: 2%;
  margin-right: 2%;
}

.text-div {
  color: #4E4E4E;
  font-size: 14px;
}

.ob-white {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-right: 32%;
  height: 38px;
  margin-top: 4px;
  margin-left: 10px;
}

.text-div-white {
  font-size: 13px;
  color: #AFAFAF;
  text-align: center;
}

.ob-input {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 38px;
  margin-top: 4px;
  margin-left: 10px;
}

.input-bo {
  width: 90%;
  border-radius: 4px;
  height: 29px;
  border: 1px solid #828282;
  padding-left: 10px;
}

.p-duvidas {
  margin-top: 20px;
  color: #A3A3A3;
  font-size: 14px;
}

.inputs-violacao {
  display: grid;
}

.input-all-violacao {
  background-color: #F2F2F2;
  margin-top: 10px;
  width: 60%;
}

.input-all-violacao-mask {
  background-color: #F2F2F2;
  margin-top: 10px;
  width: 60%;
  height: 30px;
  border: 0px;
  font-size: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
}

.input-all-violacao-mask-contato {
  background-color: #F2F2F2;
  margin-top: 10px;
  width: 100%;
  height: 39px;
  border: 0px;
  font-size: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
  margin-left: 10px;
}

.label-input-violacao {
  margin-top: 10px;
  font-size: 13px;
  color: #4E4E4E;
}

.input-date-violacao {
  display: flex;
}

.sub-input-violacao {
  display: grid;
}

.sub-input-all-violacao {
  background-color: #F2F2F2;
  border: 0px;
  border-bottom: 1px solid black;
  padding-top: 10px;
  padding-bottom: 6px;
  text-align: center;
  margin-top: 5px;
  width: 60%;
}

.sub-input-all-violacao-contato {
  background-color: #F2F2F2;
  width: 110%;
}

.p-observacao {
  margin-top: 30px;
  color: #A3A3A3;
  font-size: 14px;
  margin-bottom: 4px;
}

.local-check {
  margin-top: 30px;
}

.p-check {
  color: #4E4E4E;
  font-size: 13px;
}

.mt {
  margin-top: 1.5%;
}

.button-reclamacao {
  border: 0px;
  background-color: #22B573;
  color: white;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-right: 10px;
  margin-top: 30px;
  margin-bottom: 10px;
  cursor: pointer;
}

.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 21px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-top: 3%;
}

/* Hide the browser's default radio button */

.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */

.checkmark {
  position: absolute;
  top: 14px;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: rgb(196, 196, 196);
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */

.container:hover input~.checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */

.container input:checked~.checkmark {
  background-color: rgb(196, 196, 196);
}

/* Create the indicator (the dot/circle - hidden when not checked) */

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */

.container input:checked~.checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */

.container .checkmark:after {
  top: 6px;
  left: 6.5px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #3f3f3f;
}

.lelo {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 21px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */

.lelo input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */

.checkmark2 {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: rgb(196, 196, 196);
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */

.lelo:hover input~.checkmark2 {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */

.lelo input:checked~.checkmark2 {
  background-color: rgb(0, 58, 13);
}

/* Create the indicator (the dot/circle - hidden when not checked) */

.checkmark2:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */

.lelo input:checked~.checkmark2:after {
  display: block;
}

/* Style the indicator (dot/circle) */

.lelo .checkmark2:after {
  top: 6px;
  left: 6.5px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}

.error-input {
  display: none;
  text-align: center;
  margin-top: 10px;
  color: #dd1111;
}

.id-atendimento {
  background-color: #f2f2f2;
  width: 280px;
  text-align: center;
  display: grid;
  align-items: center;
  color: #22b573;
  border-radius: 15px;
  margin-left: 32%;
}

.id {
  background-color: white;
  width: 80%;
  margin-left: 11%;
  font-weight: bold;
  padding-top: 6px;
  padding-bottom: 6px;
}

.manipula-id {
  display: none;
}

.button-manipula {
  border: 0px;
  background-color: #ffd247;
  color: black;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-left: 41%;
  margin-top: 30px;
  font-weight: bold;
  margin-bottom: 10px;
}

@media(max-width: 500px) {
  .app-header {
    margin-left: 1%;
    margin-right: 6%;
  }
  .corpo {
    padding-left: 4%;
  }
  .ob-white {
    height: auto;
    margin-left: 0px;
    width: 100%;
    padding: 0px;
  }
  .container {
    margin-bottom: 0px;
    margin-top: 0px;
  }
  .ob {
    height: auto;
  }
  .p-observacao {
    margin-top: 15px;
  }
  .dis-mob {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}


