.title {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: justify;
  margin-top: 5%;
}

.subtitle-1 {
  color: #818181;
  font-size: 15px;
}

.subtitle-2 {
  margin-top: 3px;
  color: black;
  font-size: 20px;
}

.texto-principal {
  display: grid;
  margin-top: 5%;
  margin-left: 4%;
  margin-right: 4%;
}

.img-logo{
  width: 80%;
}
.paragrafo-1 {
  color: #818181;
  font-size: 12px;
  text-align: justify;
  margin-top: 20px;
}

.area-button {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 2%;
}

.whatsButton {
  background-color: #25D366;
  border: 0;
  color: white;
  padding-top: 13px;
  padding-bottom: 13px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 17px;
  width: 250px;
  cursor: pointer;
}

.loginButton {
  background-color: rgb(58, 58, 58);
  border: 0;
  color: white;
  padding-top: 13px;
  padding-bottom: 13px;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 17px;
  width: 250px;
  cursor: pointer;
}


.bord-principal{
  margin-top: 3%;
  border: 1px solid #D3D3D3;
  width: 92%;
  text-align: center;
}

.bord-align-principal{
  display: flex;
  align-items: center;
  justify-content: center;
}

.border-one{
  border: 1px solid #D3D3D3;
  height: 200px;
}

.header-title{
  display: grid;
}

@media(max-width: 500px){
  .area-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2%;
  }

  .border-one{
    display: none;
  }

  
.loginButton {
 margin-top: 5%;
}

.whatsButton{
  margin-top: 10%;
}
}

@media(max-width: 320px){
  .subtitle-2{
    font-size: 16px;
  }
}