.container-ticket-body {
    width: 500px;
    display: flex;
    padding: 5px;
    box-sizing: border-box;
}

.container-ticket {
    width: 100%;
}

.container-ticket-top {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    width: 100%;
    box-shadow: 3px 4px 9px #0000000A;
    border-radius: 5px;
    opacity: 1;
    margin-left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
}

.container-ticket-left {
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.ticket-left-items {
    width: 75%;
}

.container-ticket-data-empresa {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
    padding-left: 0;
    box-sizing: border-box;
    width: 350px;
}

.text-data {
    color: #828282;
    margin-right: 10px;
}

.text-empresa {
    color: #828282;
    text-align: left;
    width: 60%;
}

.borda-ticket-status {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    width: 5px;
}

.text-status {
    padding:5px;
    padding-left: 0;
    align-items: center;
    color: #828282;

}

.text-resposta{
    align-items: center;
    color: #828282;
    margin-right: 5px;
}

.text-atendimento {
    font-weight: bold;
    font-size: 1em;
    color: #4E4E4E;
    padding:5px;
    padding-left: 0;
    white-space: nowrap;
}

.ticket-ver {
    font-weight: bold;
    margin-right: 25px;
    cursor: pointer;
    height: 75px;
    display: flex;
    align-items: center;
}

.container-close-button {
    margin: 5px;
    margin-top:0;
    margin-bottom: auto;
    border-radius: 2px;
    height: 25px;
    width: 35px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.close-button {
    background: #4E4E4E 0% 0% no-repeat padding-box;
    border-radius: 2px;
    height: 4px;
    width: 20px;
}

.ticket-status-color {
    font-weight: bold;
}

.ticket-select {
    background: #F2F2F2 0% 0% no-repeat padding-box;
    color: #828282;
    font-size: 0.9em;
    font-weight: bold;
    box-sizing: border-box;
    padding: 5px;
    border: none;
    margin-left: 5px;
    cursor: pointer;
}

.ticket-select-option {
    color: #828282;
    font-size: 0.9em;
    font-weight: bold;
}


.container-ticket-bottom {
    width: 100%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    display: flex;
    flex-direction: column;
    padding: 15px;
    padding-top: 0;
}

.ticket-resposta{
    margin-top: 10px;
    display: flex;
}

.container-ticket-linha {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
}

.ticket-linha {
    background: #D3D3D3 0% 0% no-repeat padding-box;
    height: 2px;
    width: 100%;
}

.ticket-objetivo {
    margin-bottom: 15px;
}

.ticket-objetivo-1 {
    color: #828282;
    font-size: 0.9em;
    white-space: nowrap;
}

.ticket-objetivo-2 {
    font-size: 1em;
    font-weight: bold;
    color: #4E4E4E;
}

.ticket-artigo {
    margin-bottom: 15px;
}

.ticket-artigo-1 {
    color: #828282;
    font-size: 0.9em;
}

.ticket-artigo-2 {
    font-size: 1em;
    font-weight: bold;
    color: #4E4E4E;
}

.ticket-dados-linha-1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    margin-bottom: 15px;
}

.ticket-telefone {
    width: 60%;
}

.ticket-telefone-1 {
    color: #828282;
    font-size: 0.9em;
}

.ticket-telefone-2 {
    font-size: 1em;
    font-weight: bold;
    color: #4E4E4E;
}

.ticket-empresa {
    width: 40%;
}

.ticket-empresa-1 {
    color: #828282;
    font-size: 0.9em;
}

.ticket-empresa-2 {
    font-size: 1em;
    font-weight: bold;
    color: #4E4E4E;
}

.ticket-dados-linha-2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    margin-bottom: 15px;
}

.ticket-data {
    width: 60%;
}

.ticket-data-1 {
    color: #828282;
    font-size: 0.9em;
}

.ticket-data-2 {
    font-size: 1em;
    font-weight: bold;
    color: #4E4E4E;
}

.ticket-horario {
    width: 40%;
}

.ticket-horario-1 {
    color: #828282;
    font-size: 0.9em;
}

.ticket-horario-2 {
    font-size: 1em;
    font-weight: bold;
    color: #4E4E4E;
}


.ticket-form-resposta {
    color: #828282;
    font-size: 0.9em;
}

.form-textfield {
    width: 100%;
    height: 100px;
    padding: 5px;
    box-sizing: border-box;
    border: 2px solid #ccc;
    border-radius: 4px;
    background: #F2F2F2 0% 0% no-repeat padding-box;
    resize: none;
    outline: none;
    font-size: 1em;
}

.container-form-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px;
}

.form-button-1 {
    background: #828282;
    color: white;
    font-weight: bold;
    font-size: 0.9em;
    width: 50%;
    padding: 7px;
    border: none;
    outline: none;
    height: 35px;
}

.form-button-2 {
    background: #22B573;
    color: white;
    font-weight: bold;
    font-size: 0.9em;
    width: 25%;
    padding: 7px;
    height: 35px;
    border: none;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
}

@keyframes show {
    0% {
        display: none;
    }
    1% {
        height: 0%;
        display: flex;
    }
    100% {
        height: 100%;
    }
}

@keyframes hide {
    0% {
        height: 100%;
    }
    100% {
        display: none;
        height: 0%;
    }
}