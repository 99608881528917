.header-pages {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: justify;
    margin-top: 1%;
    padding-left: 10%;
    padding-right: 10%;
    margin-bottom: 1%
}

.imagens {
    display: flex;
    width: 15%;
    flex-direction: row;
    align-items: center;
}

.img-logo-1 {
    margin-right: 20px;
    max-height: 100px;
    min-width: 80px;
    width: auto;
    height: auto;
}

.header-title-page {
    width: 20%;
    margin-right: 20%;
    text-align: justify;
}

.subtitle-programa {
    letter-spacing: 0px;
}

.barra-verde {
    background-color: #455E56;
    padding-top: 5px;
    padding-bottom: 5px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.button-fale {
    border: 0;
    border-radius: 4px;
    background-color: white;
    padding-top: 7px;
    padding-bottom: 7px;
    color: #22B573;
    cursor: pointer;
}

.input-search {
    border: 0px;
    padding-top: 7px;
    padding-bottom: 7px;
    border-radius: 3px;
    padding-left: 18px;
    width: 100%;
    margin-right: 10px;
}

.wrapper-input {
    margin-left: -40px;
    display: flex;
    width: 26%;
}

.button-acessar {
    cursor: pointer;
    border: 0px;
    background-color: transparent;
    color: white;
    margin-left: -9%;
}

.info {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    background-color: #F0F5F3;
}

.info-all {
    width: 50%;
    background-color: white;
    border-top: 4px solid #22B573;
    margin-top: 2%;
    padding-left: 4%;
    padding-right: 4%;
    margin-bottom: 5%;
    min-height: 475px;
}

.title-all-info {
    min-width: 13%;
    background-color: white;
    border-top: 4px solid #22B573;
    margin-top: 2%;
    padding-left: 2%;
    padding-right: 2%;
    margin-bottom: 5%;
    /* height: 500px; */
    height: 290px;
    margin-right: 3%;
    margin-top: -43px;
}

.text-link-all-info {
    border-bottom: 1px solid #D3D3D3;
    margin-bottom: -5px;
    font-size: 14px;
}

.p-text-all {
    margin-bottom: 7px;
}

.p-title-info {
    color: #455E56;
    font-size: 24px;
    margin-top: 35px;
    margin-bottom: 35px;
}

.p-title-info-2 {
    color: #455E56;
    font-size: 20px;
    margin-top: 35px;
    margin-bottom: 35px;
    width: 103%;
}

.w3-table-all {
    width: 100%;
}

.p-text-info {
    color: #000000;
    font-size: 15px;
    margin-top: 30px;
    margin-bottom: 25px;
    width: 100%;
}

.text-p-info {
    color: #4E4E4E;
    font-size: 14px;
    text-align: justify;
    margin-bottom: 20px;
}

.button-back {
    border: 0px;
    background-color: transparent;
    cursor: pointer;
    text-align: left;
}

.table-cor-escura {
    background-color: #258C5E;
    color: white;
}

.table-cor-clara {
    background-color: #22B573;
    color: white;
}

.table-clar {
    background-color: #F0F5F3;
}

.gerenciadores {
    display: grid;
    margin-top: 20px;
    font-size: 14px;
    color: #4E4E4E;
}

.button-norma {
    border: 1px solid black;
    border-radius: 4px;
    background-color: white;
    padding-top: 7px;
    padding-bottom: 7px;
    color: black;
    cursor: pointer;
    margin-top: 30px;
    margin-right: 13px;
}

.manipula-norma-gestao {
    display: block;
}

.manipula-norma-maturidade {
    display: none;
}

.icon-list {
    display: none;
}

.btn-wpp {
    border: 0px;
    width: 181px;
    height: 30px;
    background: white;
    color: #22B573;
    font-size: 15px;
    cursor: pointer;
}

.banner-wpp {
    background: url("../../img/banner.png");
    min-height: 191px;
    width: 242px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 28px;
}

.banner-wpp-mobile {
    width: 205px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
}

.banner-mobile {
    position: absolute;
    z-index: -1;
    width: 205px;
}

.p-wpp {
    color: white;
    margin-left: 5px;
    font-size: 14px;
}

.wpp-txt {
    padding: 10px;
    display: flex;
    margin-top: 25px;
}


.p-wpp-mobile {
    color: white;
    margin-left: 5px;
    font-size: 11px;
}

.btn-wpp-mobile {
    border: 0px;
    width: 130px;
    height: 25px;
    background: white;
    color: #22B573;
    font-size: 13px;
    cursor: pointer;
}

.wpp-txt-mobile {
    padding: 10px;
    display: flex;
    margin-top: 25px;
}

.link-ouvidoria {
    margin-left: 24%;
}

.mobile {
    display: none;
}

td {
    font-size: 12px;
}

@media(max-width: 500px) {
    .mobile {
        display: none;
    }
    .web {
        display: none;
    }
    .header-pages {
        display: flex;
        flex-direction: column;
        margin-top: 50px;
        padding-left: 6%;
        padding-right: 6%;
    }
    .header-title-page {
        width: 100%;
        margin-right: unset;
    }
    .imagens {
        display: flex;
        width: 100%;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
    .img-logo-1 {
        margin-right: 10px;
        max-width: 100px;
        width: auto;
        height: auto;
        min-width: unset;
    }

    .img-log-sqr {
        max-width: 80px;
    }

    .title-all-info {
        min-width: 13%;
        background-color: white;
        border-top: 4px solid #22B573;
        position: fixed;
        padding-left: 2%;
        padding-right: 2%;
        height: 450px;
        margin-right: 0%;
        top: 83px;
        left: 0px;
    }
    .info-all {
        width: 85%;
    }
    .hamburger-header {
        width: 100%;
        height: 40px;
        position: fixed;
        background-color: #455e56;
        margin-top: -50px;
    }
    .icon-list {
        display: block;
        font-size: 40px;
        color: #ffc600;
    }
    .link-ouvidoria {
        margin-left: 0%;
    }
    .button-fale {
        font-size: 11px;
    }
    .input-search {
        font-size: 11px;
    }
    .button-acessar {
        font-size: 12px;
    }
    .wrapper-input {
        margin-left: 0px;
    }
    .font-mobile {
        font-size: 12px;
    }
}

.comp-prob {
    display: flex;
}

.componentes-probare {
    margin-left: 20px;
}

@media(max-width: 325px) {
    .font-mobile {
        font-size: 10px;
    }   
}

@media(max-width: 700px) {
    .barra-verde {
        display: grid;
        grid-gap: 6px 0px;
        grid-template-rows: auto auto;
        grid-template-columns: auto auto;
    }
    .input-search {
        width: fit-content;
    }
    .barra-verde > a:last-of-type {
        grid-row: 1;
        grid-column: 2;
    }
}